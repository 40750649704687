import Swiper, {Pagination, Autoplay, Navigation, Thumbs} from "swiper";

export class Slider {
  constructor() {

    if (document.querySelector('.slider--home')) {
      var sliderHome = this.registerSlider(document.querySelector('.slider--home'))
    }

    if (document.querySelector('.slider--video')) {
      var sliderHome = this.registerSlider(document.querySelector('.slider--video'))
    }

    this.registerSyncedSlider()

  }

  registerSyncedSlider() {

    if (document.querySelector('.slider--report') && document.querySelector('.slider--carousel')) {

      var swiper = new Swiper(".slider--carousel", {
        spaceBetween: 10,
        breakpoints: {
          300: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          1100: {
            slidesPerView: 4
          }
        },
        autoHeight: true,
        freeMode: true,
        watchSlidesProgress: true,
      });
      var swiper2 = new Swiper(".slider--report", {
        modules: [Thumbs,Navigation],
        spaceBetween: 10,
        autoHeight: true,
        thumbs: {
          swiper: swiper,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });


    }

  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let nav = slider.getAttribute('navigation')

    const modules = [Pagination, Autoplay]
    let navigation = {}
    let pagination = {}
    modules.push(Navigation)
    navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
    pagination = {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="swiper-pagination-bullet"></span>'
      }
    }


    const
      swiper = new Swiper('.' + name, {
        modules: modules,
        slidesPerView: per_view,
        spaceBetween: 15,
        loop: false,
        autoplay: {
          delay: 5000
        },
        navigation: navigation,
        pagination: pagination,
        breakpoints: {
          300: {
            slidesPerView: per_view_sm,
          },
          768: {
            slidesPerView: per_view_sm
          },
          1100: {
            slidesPerView: per_view
          }
        }
      });

    return swiper


  }
}
