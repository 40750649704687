export class Form {

  constructor() {
    this.handlePreview()
    this.confirmPassword()
  }

  handlePreview() {
    const fileInput = document.getElementById('file-photo')
    const preview = document.getElementById('preview')

    if (fileInput) {
      fileInput.addEventListener('change', (e) => {
        const [file] = fileInput.files
        if (file) {
          preview.src = URL.createObjectURL(file)
        }
      })
    }
  }

  confirmPassword() {

    const passInput = document.getElementById('pass')
    const passConfirmInput = document.getElementById('passconfirm')
    passConfirmInput.addEventListener('keyup', this.checkPassword)
    passInput.addEventListener('keyup', this.checkPassword)

  }

  checkPassword() {

    const passInput = document.getElementById('pass')
    const passConfirmInput = document.getElementById('passconfirm')
    const response = document.getElementById('response')

    const submitInput = document.getElementById('update-password')

    let pass = passConfirmInput.value
    let pass2 = passInput.value

    if (pass !== pass2) {
      passConfirmInput.classList.add('error')
      response.textContent = "Les mots de passe ne correspondent pas"
      submitInput.setAttribute('disabled', 'disabled')
    } else {
      passConfirmInput.classList.remove('error')
      response.textContent = ""
      submitInput.removeAttribute('disabled')
    }

    return true

  }

}
