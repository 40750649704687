export class Flash {

  constructor() {

    const flash = document.querySelector('.flashinfos')
    const status = window.sessionStorage.getItem('flash')

    if (flash && status !== 'close') {

      flash.style.display = "block"

      const close = document.querySelector('.flashinfos__close')

      close.addEventListener('click', (event) => {

        window.sessionStorage.setItem('flash', 'close')


        flash.style.display = "none"

      })

    }

  }

}
