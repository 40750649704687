export class Font {

  constructor() {

    const fontPlus = document.querySelector('.font-size-up')
    const fontMinus = document.querySelector('.font-size-down')

    let fontSize = 100

    if(fontPlus) {

      fontPlus.addEventListener('click', (e) => {
        if(fontSize < 130) {
          fontSize += 10;
          document.querySelector('html').style.fontSize = fontSize+'%'
        }
      })
    }

    if(fontMinus) {

      fontMinus.addEventListener('click', (e) => {
        if(fontSize > 80) {
          fontSize = fontSize - 10;
          document.querySelector('html').style.fontSize = fontSize+'%'
        }

      })

    }

  }
}
