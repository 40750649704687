export class Modal {
  constructor() {

    if(document.getElementById('edit-photo')) {

      document.getElementById('edit-photo').addEventListener('click', () => {
        document.getElementById('modal-photo').showModal()
      })

    }

    if(document.getElementById('edit-password')) {

      document.getElementById('edit-password').addEventListener('click', () => {
        document.getElementById('modal-password').showModal()
      })

    }


  }
}
